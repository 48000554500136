import React from "react";
import { Header } from "semantic-ui-react";

function CardBasedAdventureGame() {
  return (
    <div>
      <Header as="h1">CardBasedAdventureGame</Header>
    </div>
  );
}

export default CardBasedAdventureGame;
