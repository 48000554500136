import React from "react";
import { Header } from "semantic-ui-react";

function SiteMap() {
  return (
    <div>
      <Header as="h1">Site Map</Header>
    </div>
  );
}

export default SiteMap;
