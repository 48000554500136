import React from "react";
import { Header } from "semantic-ui-react";

function PrivacyPolicy() {
  return (
    <div>
      <Header as="h1">Privacy Policy</Header>
    </div>
  );
}

export default PrivacyPolicy;
