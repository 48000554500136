import React from "react";
import { Header } from "semantic-ui-react";
import { Link } from "react-router-dom";

function Projects() {
  return (
    <div>
      <Header as="h1">Projects</Header>
      <Link to="/projects">Projects</Link>
      <Link to="/card-based-adventure-game">CBAG</Link>
      <Header as="h3">Joey</Header>
    </div>
  );
}

export default Projects;
