import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./pages/Main";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import SiteMap from "./pages/SiteMap";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CardBasedAdventureGame from "./pages/CardBasedAdventureGame";

import NoPage from "./pages/NoPage";

import "semantic-ui-css/semantic.min.css";
import "./style/core.css";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route
            path="card-based-adventure-game"
            element={<CardBasedAdventureGame />}
          />
          <Route path="site-map" element={<SiteMap />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
