import React from "react";

import { Outlet, Link } from "react-router-dom";
import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  List,
  Menu,
  Segment,
} from "semantic-ui-react";

import "semantic-ui-css/semantic.min.css";
import "../style/core.css";
import BlingIcon from "../style/bling-icon.svg";

function Main() {
  return (
    <div>
      <Menu fixed="top" inverted>
        <Container>
          <Menu.Item as="a" header href="/">
            <Image
              size="mini"
              src={BlingIcon}
              style={{ marginRight: "1.5em" }}
            />
            blingtronics.com
          </Menu.Item>
          <Menu.Item>
            <Link to="projects">Projects</Link>
          </Menu.Item>
        </Container>
      </Menu>

      <Container text style={{ marginTop: "7em" }}>
        <Outlet />
      </Container>

      <Segment
        inverted
        vertical
        style={{ margin: "5em 0em 0em", padding: "5em 0em" }}
      >
        <Container textAlign="center">
          <Grid inverted stackable>
            <Grid.Column width={4}>
              <Header inverted as="h4">
                <Link to="/projects">Projects</Link>
              </Header>
              <List link inverted>
                <List.Item>
                  <Link to="/card-based-adventure-game">
                    Card Based Adventure Game
                  </Link>
                </List.Item>
              </List>
            </Grid.Column>
            {/* <Grid.Column width={10}>
              <Header inverted as="h4" content="Footer Header" />
              <p>
                Extra space for a call to action inside the footer that could
                help re-engage users.
              </p>
            </Grid.Column> */}
          </Grid>
          <Divider inverted section />
          <Image centered size="mini" src={BlingIcon} />
          <List horizontal inverted divided link size="small">
            <List.Item>
              <Link to="/">home</Link>
            </List.Item>
            <List.Item>
              <Link to="site-map">site map</Link>
            </List.Item>
            <List.Item>
              <Link to="contact-us">contact us</Link>
            </List.Item>
            <List.Item>
              <Link to="privacy-policy">privacy policy</Link>
            </List.Item>
          </List>
          <p>
            <small>copyright &copy; 2023</small>
          </p>
        </Container>
      </Segment>
    </div>
  );
}

export default Main;
