import React from "react";
import { Header, Image } from "semantic-ui-react";

import BlingtronicsLogo from "../style/blingtronics - logo.svg";

function Home() {
  return (
    <div>
      <Header as="h1">Semantic UI React Fixed Template</Header>
      <Image centered size="medium" src={BlingtronicsLogo} />
    </div>
  );
}

export default Home;
